import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { beat3InternalRoutes, functions, getHeaders, bountyUpdateRoutes } from '../http';
import { cloneDeep } from 'lodash';
import { useCustomData, BountyInfo, useUpdateUser, useUser, useToken } from '../user';

export type BountyUpdateOperation = 'start-bounty' | 'finish-bounty';
export type Bounty = any;
export type BountiesMap = Map<string, Bounty> | null;
export type BountyState = [BountiesMap, ((bounty: Bounty) => void) | null, (() => void) | null];
export const BountyContext = createContext<BountyState>([null, null, null]);

const cancelRequest = axios.CancelToken.source();

export interface CategoryMeta {
	name: string;
	description: string;
}

const categoryMap: Array<{ key: string, value: CategoryMeta }> = [];

export function useFetchBounties(): BountyState {
	const token = useToken();
	const [bounties, setBounties] = useState<BountiesMap>(null);
	const [refreshFlag, setRefreshFlag] = useState<boolean>(false);

	const refresh = () => {
		setRefreshFlag(!refreshFlag);
	};

	useEffect(() => {
		axios.get(functions.callBeat3Internal, { headers: getHeaders(token, beat3InternalRoutes.getExtensions) })
			.then(({ data: userData }) => {
				for (const category of userData.loyalty.reward_categories.categories) {
					// We only want to consider categories with type === 'app'
					if (!category.type || category.type !== 'app') {
						continue;
					}

					const found = categoryMap.find(el => el.key === category.key);

					if (!found) {
						categoryMap.push({
							key: category.key,
							value: {
								name: category.name,
								description: category.description
							}
						});
					}
				}

				axios.get(functions.callBeat3Internal, { headers: getHeaders(token, beat3InternalRoutes.getBounties) })
					.then(({ data: bountyData }) => {
						const bountiesMapBuf = new Map<string, Bounty>();
						const orderMap: any = categoryMap.map(el => ({ key: el.key, value: null }));

						// Get all bounties (not ordered by categories
						bountyData.in_shop
							// Only consider bounties with a category
							.filter((bounty: any) => Boolean(bounty.category))
							// Only consider bounties with stored category
							.filter((bounty: any) => !!categoryMap.find(el => el.key === bounty.category))
							.forEach((bounty: any) => bountiesMapBuf.set(bounty.id, bounty));

						// Create a bounties-map ordered by categories
						const bountiesMap = orderMap
							.map((el: any) => {
								const bounties = new Map<string, Bounty>();
								for (const bufferedBounty of bountiesMapBuf) {
									if (bufferedBounty[1].category === el.key) {
										bounties.set(bufferedBounty[0], bufferedBounty[1]);
									}
								}
								el.value = bounties;
								return el;
							})
							.reduce((acc: Map<string, Bounty>, cur: { key: string, value: Map<string, Bounty> }) => {
								return new Map([...acc].concat([...cur.value]));
							}, new Map<string, Bounty>());
						setBounties(bountiesMap);
					});
			});
		return () => cancelRequest.cancel();
	}, [refreshFlag, token]);

	const updateSingleBounty = (bounty: Bounty): void => {
		if (!bounties) {
			return;
		}

		const _bounties = cloneDeep(bounties);

		const found = _bounties.get(bounty.id);

		if (!found) {
			return;
		}

		_bounties.delete(found);
		_bounties.set(bounty.id, bounty);
		setBounties(_bounties);
	};

	return [bounties, updateSingleBounty, refresh];
}

export function useBounties(): BountyState {
	return useContext(BountyContext);
}

export function useBountySearch(bountyId: string): Bounty | null {
	const [bounties] = useBounties();

	if (!bounties) {
		return null;
	}

	for (const bounty of bounties.values()) {
		if (bounty.id === bountyId) {
			return bounty;
		}
	}

	return null;
}

export function useCategorizedBounties(): Map<string, Bounty[]> | null {
	const [bounties] = useBounties();
	const result = new Map<string, Bounty[]>();

	if (!bounties) {
		return null;
	}

	for (const bounty of bounties.values()) {
		const category = bounty.category;
		const storedBounties = result.get(category);

		if (!storedBounties) {
			result.set(category, [bounty]);
			continue;
		}

		const storedBounty = storedBounties.find(b => b.id === bounty.id);

		if (!storedBounty) {
			storedBounties.push(bounty);
		}
	}

	return result;
}

export function useBountiesByChallenge(challengeName: string): Bounty[] {
	const bounties = useCategorizedBounties()?.get(challengeName);

	if (!bounties) {
		return [];
	}

	return bounties;
}

export function getCategoryMeta(key: string): CategoryMeta {
	return categoryMap.find(el => el.key === key)!.value;
}

export interface UpdateBountyArgument {
	bounty: Bounty;
	bountyInfo: BountyInfo;
}

export function isSpecialCategory(category: string): boolean {
    return ['xmas', 'diy1'].includes(category);
}

export function useUpdateBounty(bountyId: string): (arg: UpdateBountyArgument, operation: BountyUpdateOperation) => Promise<void> {
	const token = useToken();
	const [bounties, updateBounty, refreshBounties] = useBounties();
	const [user] = useUser();
	const userData = useCustomData();
	const updateUser = useUpdateUser();

	if (!user || !userData || !bounties || !updateBounty) {
		throw new Error('Fatal error: App is not initialized yet.');
	}

	return async (arg: UpdateBountyArgument, operation: BountyUpdateOperation): Promise<void> => {
		const data = {
			user_id: user.id,
			bounty_data: arg
		};

		const route = operation === 'start-bounty'
			? bountyUpdateRoutes.activate
			: bountyUpdateRoutes.redeem;

		let shouldRefreshState = () => {
            if (!isSpecialCategory(arg.bounty.category)) {
				return true;
			} else if (operation === 'finish-bounty') {
				return true;
			}
			return false;
		};

		// Start or redeem a bounty
		await axios
			.post(functions.updateUserBounty, data, { headers: getHeaders(token, route) })
			.catch(error => {
				console.log('GOT ERROR WHILE UPDATING BOUNTY', error);
				throw error;
			})
			.finally(() => {
				if (shouldRefreshState()) {
					refreshBounties && refreshBounties();
				}
			});

		// Set the correct flags for th react state.
		if (operation === 'start-bounty') {
			arg.bounty.is_activated = true;
		} else {
			arg.bounty.is_redeemed = true;
		}

		// Update the current react state
		if (shouldRefreshState()) {
			updateBounty(arg.bounty);
		}

		// Store estimated / actual days for the bounty.
		const bountyFound = userData.startedBounties.find(info => info.id === bountyId);
		if (!bountyFound) {
			userData.startedBounties.push(arg.bountyInfo);
		} else {
			// TODO: Bounty is being tracked!!!!
			const index = userData.startedBounties.findIndex(bounty => bounty.id === bountyId);
			userData.startedBounties[index] = arg.bountyInfo;
		}

		// Update user-information
		await updateUser(userData, shouldRefreshState());
	};
}
