import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useBountySearch, useUpdateBounty, BountyUpdateOperation, Bounty, isSpecialCategory } from '../../bounties';
import { Icon } from '@rmwc/icon';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TopBanner } from '../../components/top-banner';
import { Arrows } from '../../components/arrows';
import { BountyInfo, useCustomData } from '../../user';
import '@rmwc/button/styles';
import '@rmwc/circular-progress/styles';
import './bounty-details.scss';
import { BannerColorType } from '../../components/colored-banner';

type ScreenMode = 'start' | 'finish' | 'done';

const getScreenMode = (bounty: Bounty | null): ScreenMode => {
    if (isSpecialCategory(bounty.category)) {
        if (bounty.redeem_count > 0) {
            return 'done';
        }
        return 'finish';
    }

    if (bounty.is_activated) {
        return 'finish';
    }

    if (bounty.redeem_count > 0) {
        return 'done';
    }

    return 'start';
};

export function BountyDetails() {
    const MIN_DAYS = 1;
    const MAX_DAYS = 3;

    const history = useHistory();
    const { pathname } = useLocation();

    const bountyId = pathname.split('bounty/')[1];

    const bounty = useBountySearch(bountyId);
    const userData = useCustomData();
    const updateBounty = useUpdateBounty(bountyId);

    let bountyInfo = userData?.startedBounties?.find(b => b.id === bountyId) ?? null;
    const isXmasSpecial = bounty.category === 'xmas';
    const isDiySpecial = bounty.category === 'diy1';
    const isSpecialBounty = isXmasSpecial || isDiySpecial;

    const [numberOfDays, setNumberOfDays] = useState<number>(1);
    const [saving, setSaving] = useState<boolean>(false);
    const [screenMode, setScreenMode] = useState<ScreenMode>('start');

    // We want to execute this side effect only once after the component has mounted.
    useEffect(() => {
        const mode = getScreenMode(bounty);
        setScreenMode(mode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNumberChange = (days: number): void => {
        let _days = days;
        if (_days < MIN_DAYS) {
            _days = MIN_DAYS;
        }
        if (_days > MAX_DAYS) {
            _days = MAX_DAYS;
        }
        setNumberOfDays(_days);
    };

    const doBountyUpdate = async (_bountyInfo: BountyInfo, updateOperation: BountyUpdateOperation): Promise<void> => {
        // In this scenario
        await updateBounty({ bounty, bountyInfo: _bountyInfo }, updateOperation);
    };

    const startBounty = async (): Promise<void> => {
        bountyInfo = {
            id: bountyId,
            estimatedDays: numberOfDays,
        };

        await doBountyUpdate(bountyInfo, 'start-bounty');
    };

    const finishBounty = async (): Promise<void> => {
        if (!bountyInfo) {
            // TODO: Potential bug
            // This ideally should not happen. However, it can if synchronizing our custom user data (estimated and
            // actual days) with the actual backend-data fails. For now, we can ignore it as we don't use the
            // estimatedDays field. In case we do start using it, we need to fix this.
            // EDIT: This is okay for xmas-special bounties as users will immediately get to the finish-screen
            bountyInfo = {
                id: bountyId,
                estimatedDays: 1,
            };
        }
        bountyInfo.actualDays = numberOfDays;
        await doBountyUpdate(bountyInfo, 'finish-bounty');
    };

    const handleButtonClick = async (): Promise<void> => {
        if (saving) {
            return;
        }

        if (!userData) {
            return;
        }

        setSaving(true);

        if (isSpecialBounty) {
            try {
                await startBounty();
                await finishBounty();
                if (isXmasSpecial) {
                    history.push('/bounties/xmas');
                } else {
                    history.push('/bounties/diy1');
                }
                return;
            } catch (error) {
                setSaving(false);
            }
        }

        if (screenMode === 'start') {
            await startBounty()
                .then(() => history.push('/'))
                .catch(() => {
                    setSaving(false);
                });
        } else if (screenMode === 'finish') {
            await finishBounty()
                .then(() => history.push('/'))
                .catch(() => {
                    setSaving(false);
                });
        } else {
            throw new Error('Clicked on an action button on the done-bounty screen');
        }
    };

    const decreaseDays = (): void => handleNumberChange(numberOfDays - 1);
    const increaseDays = (): void => handleNumberChange(numberOfDays + 1);

    const getNumberPickerAnnotation = (): string => {
        if (screenMode === 'start') {
            return 'WIE VIELE TAGE SCHAFFST DU?';
        }
        return 'WIE VIELE TAGE HAST DU GESCHAFFT?';
    };

    const getButtonLabel = (): string => {
        if (saving) {
            return '';
        }
        if (screenMode === 'start') {
            return 'LOSLEGEN';
        }
        return 'GESCHAFFT';
    };

    const split = bounty.description.split('{{{}}}');
    const upperText = split[1] ?? '';
    const lowerText = split[2] ?? '';

    const getNumberPicker = (): JSX.Element => {
        // We don't want to display the day picker for xmas-special bounties.
        if (isSpecialBounty) {
            return (
                <>
                    <br />
                </>
            );
        }

        return (
            <>
                <div className="single-bounty__days">
                    <strong>{getNumberPickerAnnotation()}</strong>
                    <div className="single-bounty__input-wrapper">
                        <div
                            className={`single-bounty__decrease-days${
                                numberOfDays === MIN_DAYS ? ' single-bounty__decrease-days--disabled' : ''
                            }`}
                            onClick={decreaseDays}>
                            <Icon icon="remove" />
                        </div>
                        <div className="single-bounty__number-input">
                            <input
                                type="number"
                                value={numberOfDays}
                                onChange={evt => handleNumberChange(+evt.target.value)}
                            />
                        </div>
                        <div
                            className={`single-bounty__increase-days${
                                numberOfDays === MAX_DAYS ? ' single-bounty__decrease-days--disabled' : ''
                            }`}
                            onClick={increaseDays}>
                            <Icon icon="add" />
                        </div>
                    </div>
                </div>

                <Arrows />
            </>
        );
    };

    const getSuccessGif = (): string => {
        if (isSpecialBounty) {
            if (isXmasSpecial) {
                return '/assets/images/xmas.gif';
            }
            if (isDiySpecial) {
                return '/assets/images/thumbs-up.gif';
            }
        }
        return '/assets/images/checkmark.gif';
    };

    const getSingleBountyCssClass = (): string => {
        let base = 'single-bounty__content';
        if (isXmasSpecial) {
            base += ' single-bounty__content--xmas';
        } else if (isDiySpecial) {
            base += ' single-bounty__content--diy';
        }
        return base;
    };

    const getSingleBountyDoneCssClass = (): string => {
        let base = 'single-bounty__done';
        if (isXmasSpecial) {
            base += ' single-bounty__done--xmas';
        } else if (isDiySpecial) {
            base += ' single-bounty__done--diy';
        }
        return base;
    };

    const getTopBannerBgColor = (): BannerColorType => {
        if (isXmasSpecial) {
            return 'red';
        }

        if (isDiySpecial) {
            return 'purple';
        }

        return 'green';
    };

    const getMainContent = (): JSX.Element => {
        if (screenMode === 'done') {
            const image = getSuccessGif();
            const dimensions = isSpecialBounty ? 300 : 480;

            return (
                <div className={getSingleBountyDoneCssClass()}>
                    <img src={image} alt="Geschafft" width={dimensions} height={dimensions} />
                </div>
            );
        }
        return (
            <>
                {getNumberPicker()}

                <div className="single-bounty__actions">
                    <Button
                        outlined
                        className="single-bounty__start-button"
                        label={getButtonLabel()}
                        icon={saving && <CircularProgress />}
                        onClick={handleButtonClick}
                        disabled={saving}
                    />
                </div>
            </>
        );
    };

    return (
        <main className="limit-width main">
            <TopBanner message={bounty.name} messageAlign="left" backgroundColor={getTopBannerBgColor()} />
            <div className="single-bounty">
                <div className={getSingleBountyCssClass()} dangerouslySetInnerHTML={{ __html: upperText }} />
                {getMainContent()}
            </div>
            <div className={getSingleBountyCssClass()} dangerouslySetInnerHTML={{ __html: lowerText }} />
        </main>
    );
}
