import axios from 'axios';
import { cloneDeep } from 'lodash';
import { functions, getHeaders } from '../http';
import { createContext, useContext, useEffect, useState } from 'react';
import { useToken } from './token.service';

interface UserCustomField {
	key: string;
	value: string;
}

interface User {
	id: string;
	custom_fields: UserCustomField[];
	status: any;
}

export interface BountyInfo {
	id: string;
	estimatedDays: number;
	actualDays?: number;
}

export interface CustomData {
	startedBounties: BountyInfo[];
}

export type UserState = [User | null, any, (() => void) | null];

export const UserContext = createContext<UserState>([null, null, null]);

export function useFetchUser(): UserState {
	const token = useToken();
	const [user, setUser] = useState<User | null>(null);
	const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

	const refresh = () => {
		setRefreshToggle(!refreshToggle);
	}

	useEffect(() => {
		axios.get(functions.userInfo, { headers: getHeaders(token) }).then(({ data: user }) => {
			setUser(user);
		});
	}, [refreshToggle, token]);

	return [user, setUser, refresh];
}

export function useUser(): UserState {
	return useContext(UserContext);
}

export function useCustomData(): CustomData | null {
	const [user] = useUser();
	if (!user) {
		return null;
	}
	for (const field of user.custom_fields) {
		if (field.key === 'challenge_information') {
			if (!field.value) {
				return { startedBounties: [] };
			}
			return JSON.parse(field.value);
		}
	}
	return { startedBounties: [] };
}

export function useUserStatus(): any | null {
	const [user,] = useUser();
	return user?.status ?? null;
}

export function useUpdateUser(): (data: CustomData, shouldRefreshState?: boolean) => Promise<void> {
	const token = useToken();
	const [user, setUser, refresh] = useUser();

	return async (data: CustomData, shouldRefreshState = true): Promise<void> => {
		const body = {
			'custom_fields': [{
				'key': 'challenge_information',
				'value': JSON.stringify(data)
			}]
		};

		await axios
			.patch(functions.userInfo, JSON.stringify(body), { headers: getHeaders(token) })
			.finally(() => {
				if (shouldRefreshState) {
					refresh!();
				}
			});

		if (shouldRefreshState) {
			const _user = cloneDeep(user!);
			updateUserObject(_user, data);
			setUser(_user);
		}
	};
}

function updateUserObject(user: User, data: CustomData): void {
	for (const field of user.custom_fields) {
		if (field.key === 'challenge_information') {
			field.value = JSON.stringify(data);
		}
	}
}
