import React from 'react';
import { isSpecialCategory, useCategorizedBounties } from '../../bounties';
import { BountyPreview } from '../../components/bounty-preview';
import { TopBanner } from '../../components/top-banner';
import './overview.scss';

export function Overview() {
	const categorizedBounties = useCategorizedBounties();

	if (!categorizedBounties) {
		return null;
	}

	const getChallenges = (): JSX.Element[] => {
		const challenges: JSX.Element[] = [];
		for (const [category, bounties] of categorizedBounties.entries()) {
			if (isSpecialCategory(category)) {
				continue;
			}
			challenges.push(<BountyPreview key={category} category={category} bounties={bounties}/>);
		}
		return challenges;
	};

	const getResultJsx = (): JSX.Element => {
		const challenges = getChallenges();
		const result = [];
		let rowCount = 0;
		while (challenges.length) {
			result.push(
				<div key={rowCount} className="challenges__row">
					{challenges.splice(0, 2)}
				</div>
			);
			++rowCount;
		}
		return (
			<div className="challenges">
				{result}
			</div>
		);
	}

	return (
		<>
			<main className="limit-width main">
				<TopBanner message="Deine Challenges"/>
				{getResultJsx()}
			</main>
		</>
	);
}
