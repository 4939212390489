import React from 'react';
import { LinearProgress } from '@rmwc/linear-progress';
import '@rmwc/linear-progress/styles';
import './progress.scss';

interface Props {
	val: number;
	max: number;
}

export function Progress({ val, max }: Props) {
	const progress = val / max;
	return <LinearProgress progress={progress} buffer={0}/>;
}
