import React from 'react';
import { UserController } from './user';
import { AppRouter } from './pages';
import { Loading } from './components/loading';
import './App.scss';

function App() {
	return <UserController onLoading={<Loading/>} onReady={<AppRouter/>}/>;
}

export default App;
