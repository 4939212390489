import React from 'react';
import { useHistory } from 'react-router-dom';
import { Bounty, getCategoryMeta } from '../../bounties';
import { Progress } from '../progress';
import './bounty-preview--responsive.scss';
import './bounty-preview.scss';

interface Props {
	category: string;
	bounties: Bounty[];
}

export function BountyPreview({ category, bounties }: Props) {
	const history = useHistory();
	const categoryMeta = getCategoryMeta(category);

	let started = 0;
	let finished = 0;
	for (const bounty of bounties) {
		if (bounty.is_activated) {
			++started;
		}
		if (bounty.redeem_count > 0) {
			++finished;
		}
	}

	return (
		<div key={category} className="challenge-preview" onClick={() => history.push(`/bounties/${category}`)}>
			<p className="challenge-preview__count">Du machst {started} Challenges</p>
			<div className="challenge-preview__content">
				<div className="challenge-preview__half-circle">
					<h4>{categoryMeta.name}</h4>
				</div>
			</div>
			<p className="challenge-preview__description">{categoryMeta.description}</p>
			<Progress val={finished} max={bounties.length}/>
		</div>
	);
}
