import React, { useEffect, useState } from 'react';
import './top-banner.scss';
import { BannerColorType, ColoredBanner } from '../colored-banner';
import { useUserStatus } from '../../user';
import ReactCountUp from 'react-countup';

export type MessageAlign = 'left' | 'center';

interface Props {
	message: string;
	messageAlign?: MessageAlign;
	backgroundColor?: BannerColorType;
}

export function TopBanner({ message, messageAlign, backgroundColor }: Props) {
	const { points } = useUserStatus();
	const [start] = useState<number>(points);
	const [end, setEnd] = useState<number>(points);

	useEffect(() => {
		setEnd(points);
	}, [points]);

	const _messageAlign = messageAlign ?? 'center';

	const alignStyle = _messageAlign === 'left'
		? 'flex-start'
		: 'center';
	document.documentElement.style.setProperty('--message-alignment', alignStyle);

	const messageLeftMargin = _messageAlign === 'left' ? '1rem' : 0;

	return (
		<ColoredBanner colorType={backgroundColor ?? 'green'} customCssClass="banner__content">
			<p className="banner__message" style={{ textAlign: _messageAlign, marginLeft: messageLeftMargin }}>
				{message.toUpperCase()}
			</p>
			<div className="banner__points-container">
				<img src="/assets/images/beat3_trophy.png" alt="Trophy"/>
				<span>
					<ReactCountUp start={start} end={end} separator={'.'}/>
					&nbsp;Beats
				</span>
			</div>
		</ColoredBanner>
	);
}
