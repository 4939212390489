import React from 'react';
import { BountyContext, useFetchBounties } from '../../bounties';
import { useFetchUser, UserContext } from '../user.service';

interface Props {
	onReady: JSX.Element | JSX.Element[];
	onLoading: JSX.Element | JSX.Element[];
}

export function UserController({ onReady, onLoading }: Props) {
	const [user, setUser, refreshUser] = useFetchUser();
	const [bounties, updateBounty, refreshBounties] = useFetchBounties();

	return (
		<UserContext.Provider value={[user, setUser, refreshUser]}>
			<BountyContext.Provider value={[bounties, updateBounty, refreshBounties]}>
				{
					user && bounties
						? onReady
						: onLoading
				}
			</BountyContext.Provider>
		</UserContext.Provider>
	);
}
