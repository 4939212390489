import React, { useEffect, useState } from 'react';
import { Token, TokenState } from '../token.service';

// Only check for the dev access token if we're in development
if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_ACCESS_TOKEN) {
	throw new Error('No access token specified');
}

interface Props {
	children?: JSX.Element | JSX.Element[];
}

export function TokenController({ children }: Props) {
	const [token, setToken] = useState<Token>(null);

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			setToken(process.env.REACT_APP_ACCESS_TOKEN!);
			return;
		}

		const listener = (message: any) => {
			setToken(message.data.token as string);
		}

		window.addEventListener('message', listener);
		window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'getAccessToken' }));
	}, []);

	if (!token) {
		return null;
	}

	return (
		<TokenState.Provider value={token}>
			{children}
		</TokenState.Provider>
	);
}
