import React from 'react';
import { inspect } from "util";

export class ErrorBoundary extends React.Component<any, any> {
	state = {
		hasError: false,
		error: null,
		errorInfo: null
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		this.setState({ hasError: true, error: error, errorInfo: errorInfo });
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					Error: {inspect(this.state.error, true, null, false)}<br/>
					Error Info: {inspect(this.state.errorInfo, true, null, false)}
				</div>
			);
		}
		return this.props.children;
	}
}
