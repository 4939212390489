import React from 'react';
import './arrows.scss';

export function Arrows() {
	const getArrow = (key: string) => <img src="/assets/images/beat3_arrow.png" alt="arrow down" key={key}/>;
	return (
		<div className="arrows">
			{[getArrow('arrow-1'), getArrow('arrow-2')]}
		</div>
	);
}
