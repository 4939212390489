import React from 'react';
import { ListItemGraphic } from '@rmwc/list';
import { Bounty } from '../../bounties';
import './list-checkbox.scss';

interface Props {
	bountyInfo?: Bounty;
}

export function ListCheckbox({ bountyInfo }: Props) {
	const getIcon = (): { type: string, className: string } => {
		if (bountyInfo.is_activated) {
			return { type: 'indeterminate_check_box', className: 'indeterminate' };
		}

		if (bountyInfo.redeem_count > 0) {
			return { type: 'check_box', className: 'checked' };
		}

		return { type: 'check_box_outline_blank', className: '' };
	}

	const icon = getIcon();

	return <ListItemGraphic className={icon.className} icon={icon.type} />;
}
