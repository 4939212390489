import { Token } from '../user';

if (!process.env.REACT_APP_BACKEND_URL) {
	throw new Error('No backend URL specified.');
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const functions = {
	callBeat3Internal: backendUrl + 'callBeat3Internal',
	userInfo: backendUrl + 'userInfo',
	updateUserBounty: backendUrl + 'updateUserBounty',
	bookBeats: backendUrl + 'users/:id/book-beats'
}

export const beat3InternalRoutes = {
	getBounties: 'bounties-list',
	getExtensions: 'extensions'
}

export const bountyUpdateRoutes = {
	activate: 'activate/',
	redeem: 'redeem/'
}

export function getHeaders(token: Token, route?: string): any {
	const headers: any = {
		'Content-Type': 'application/json',
		'x-beat3-auth': `Token ${token}`
	};

	if (route) {
		headers['x-beat3-url'] = route;
	}

	return headers;
}
