import React, { useEffect } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';
import './loading.scss';

export function Loading() {
	useEffect(() => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, []);

	return (
		<div className="loading">
			<div className="loading__spinner-wrapper">
				<CircularProgress size={140}>Loading</CircularProgress>
				<span className="loading__text">Loading...</span>
			</div>
		</div>
	);
}
