import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { TokenController } from './user/components';
import { ErrorBoundary } from './errors/components';
import './index.scss';

declare global {
	// noinspection JSUnusedGlobalSymbols
	interface Window {
		ReactNativeWebView: any;
	}
}

ReactDOM.render(
	<ErrorBoundary>
		<TokenController>
			<App/>
		</TokenController>
	</ErrorBoundary>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
