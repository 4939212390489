import React from 'react';
import './colored-banner.scss';

export type BannerColorType = 'green' | 'blue' | 'red' | 'purple';
export interface BannerDimensions {
	height: string;
	width: string;
}

interface Props {
	children: JSX.Element | JSX.Element[];
	colorType: BannerColorType;
	dimensions?: BannerDimensions;
	customCssClass?: string;
}

export function ColoredBanner({ children, colorType, dimensions, customCssClass }: Props) {
	const _dimensions = dimensions ?? { height: '90px', width: '100%' };

	const colorClass = () => ({
		start: `colored-banner__${colorType}-start`,
		end: `colored-banner__${colorType}-end`,
		transition: `colored-banner__${colorType}-transition`
	});

	const cc = colorClass();

	return (
		<div className="colored-banner" style={_dimensions}>
			<div className={`colored-banner__background colored-banner__background--start ${cc.start}`}/>
			<div className={`colored-banner__background colored-banner__background--transition ${cc.transition}`}/>
			<div className={`colored-banner__background colored-banner__background--end ${cc.end}`}/>
			<div className={`colored-banner__overlay${customCssClass ? (' ' + customCssClass) : ''}`}
			     style={_dimensions}>
				{children}
			</div>
		</div>
	);
}
