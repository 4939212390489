import React from 'react';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { Category } from './category';
import { BountyDetails } from './bounty-details';
import { Overview } from './overview';

export function AppRouter() {
	return (
		<HashRouter>
			<Switch>
				<Route path="/bounty/:bountyId">
					<BountyDetails/>
				</Route>
				<Route path="/bounties/:categoryName">
					<Category/>
				</Route>
				<Route path="/bounties">
					<Overview/>
				</Route>
				<Route exact path="/">
					<Redirect to="/bounties"/>
				</Route>
				<Route path="*">
					<Redirect to="/bounties"/>
				</Route>
			</Switch>
		</HashRouter>
	);
}
