import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Bounty, getCategoryMeta, useBountiesByChallenge } from '../../bounties';
import '@rmwc/list/styles';
import './category.scss';
import { TopBanner } from '../../components/top-banner';
import { List, ListItem } from '@rmwc/list';
import { BannerColorType, ColoredBanner } from '../../components/colored-banner';
import { ListCheckbox } from '../../components/list-checkbox';

export function Category() {
    const { pathname } = useLocation();
    const history = useHistory();
    const challengeCategoryKey = pathname.split('bounties/')[1];
    const categoryMeta = getCategoryMeta(challengeCategoryKey);
    const bounties = useBountiesByChallenge(challengeCategoryKey);

    const isXmasSpecial = challengeCategoryKey === 'xmas';
    const isDiySpecial = challengeCategoryKey === 'diy1';

    const extractNameAndLevel = (raw: string): { bountyName: string; bountyLevel: string | null } => {
        const separationIndex = raw.toLowerCase().indexOf('level');
        let bountyName = raw;
        let bountyLevel = null;
        if (separationIndex !== -1) {
            bountyName = raw.substr(0, separationIndex).trim();
            bountyLevel = raw.substr(separationIndex).trim();
        }
        return { bountyName, bountyLevel };
    };

    const createBountyRow = (bounty: Bounty): JSX.Element => {
        const slogan = bounty.description.split('{{{}}}')[0];

        const { bountyName, bountyLevel } = extractNameAndLevel(bounty.name);

        return (
            <ListItem key={bounty.id} onClick={() => history.push(`/bounty/${bounty.id}`)}>
                <ListCheckbox bountyInfo={bounty} />
                <div className="bounties-list-item">
                    <ColoredBanner
                        colorType="blue"
                        dimensions={{ width: '100%', height: '100%' }}
                        customCssClass="bounties-list-item__banner">
                        <div className="bounties-list-item__content">
                            <div className="bounties-list-item__name">
                                {bountyName}
                                <br />
                                {bountyLevel}
                            </div>
                            <div className="bounties-list-item__description">
                                <div
                                    className="bounties-list-item__description-border"
                                    dangerouslySetInnerHTML={{ __html: slogan }}
                                />
                            </div>
                        </div>
                    </ColoredBanner>
                </div>
            </ListItem>
        );
    };

    const bountiesList = () => {
        return <List>{bounties.map(createBountyRow)}</List>;
    };

    const getTopBannerBgColor = (): BannerColorType => {
        if (isXmasSpecial) {
            return 'red';
        }

        if (isDiySpecial) {
            return 'purple';
        }

        return 'green';
    };

    return (
        <main className="limit-width main">
            <TopBanner
                message={`Deine ${categoryMeta.name} challenges`}
                messageAlign="left"
                backgroundColor={getTopBannerBgColor()}
            />
            <div className="bounties">{bountiesList()}</div>
        </main>
    );
}
